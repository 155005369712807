import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export class FormUtil {

    constructor() {}

    isFieldValid(form: UntypedFormGroup, field: string, attempt: boolean) {
        if (attempt) {
            return form.get(field).valid && form.get(field).touched;
        }

        return false;
    }

    displayFieldCss(form: UntypedFormGroup, field: string, attempt: boolean) {
        if (attempt) {
            return {
                // 'form-valid': this.isFieldValid(form, field, attempt),
                'form-invalid': !this.isFieldValid(form, field, attempt)
            };
        }

        return {};
    }

    validatePassword(c: UntypedFormControl) {
        return /\d/.test(c.value) && /[a-z]/.test(c.value) && /[A-Z]/.test(c.value) && /\W/.test(c.value) ? null : {
            validateEmail: {
                valid: false
            }
        };
    }
    
    validatePasswordOnly(password) {
        return /\d/.test(password) && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\W/.test(password);
    }

    validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}
